/**
 * WordPress Dependencies
 */
import { __ } from "@wordpress/i18n";
import {
	PanelBody,
	ToggleControl,
	RangeControl,
	ButtonGroup,
	Button,
	Flex,
} from "@wordpress/components";
import { InspectorControls } from "@wordpress/block-editor";

import { AutoPlay, IndicatorsControl, NavigationControls } from "./components";

function CustomInspectorControls(props) {
	const { attributes, setAttributes } = props;
	const { ghubSlidePerView, ghubSlideGap, ghubSpeed } = props.attributes;

	return (
		<InspectorControls>
			<PanelBody
				initialOpen={false}
				title={__("Slider Settings", "gutenberghub-slider-ext")}
			>
				<RangeControl
					label={__("Slide Per View", "gutenberghub-slider-ext")}
					value={ghubSlidePerView}
					onChange={(newValue) => setAttributes({ ghubSlidePerView: newValue })}
					min={1}
					max={6}
				/>

				<RangeControl
					label={__("Slide Gap", "gutenberghub-slider-ext")}
					value={ghubSlideGap}
					onChange={(newValue) => setAttributes({ ghubSlideGap: newValue })}
					min={0}
					max={50}
				/>

				<RangeControl
					label={__("Speed", "gutenberghub-slider-ext")}
					value={ghubSpeed}
					onChange={(newValue) => setAttributes({ ghubSpeed: newValue })}
					min={100}
					max={2000}
					step={100}
				/>

				<Flex>
					<span>{__("HEIGHT", "gutenberghub-query-slider-ext")}</span>
					<ButtonGroup>
						<Button
							isPressed={
								attributes.gutenberghubHeight === "equal" ? true : false
							}
							onClick={() => setAttributes({ gutenberghubHeight: "equal" })}
						>
							{__("Equal", "gutenberghub-query-slider-ext")}
						</Button>
						<Button
							isPressed={
								attributes.gutenberghubHeight === "auto" ? true : false
							}
							onClick={() => setAttributes({ gutenberghubHeight: "auto" })}
						>
							{__("Auto", "gutenberghub-query-slider-ext")}
						</Button>
					</ButtonGroup>
				</Flex>
				<br />

				<ToggleControl
					label={__("Loop", "gutenberghub-slider-ext")}
					checked={attributes?.ghubLoopOn}
					onChange={() =>
						setAttributes({
							ghubLoopOn: !attributes?.ghubLoopOn,
						})
					}
				/>

				<ToggleControl
					label={__("Auto Play", "gutenberghub-slider-ext")}
					checked={attributes?.gutenberghubAutoPlayOn}
					onChange={() =>
						setAttributes({
							gutenberghubAutoPlayOn: !attributes?.gutenberghubAutoPlayOn,
						})
					}
				/>
				{attributes?.gutenberghubAutoPlayOn && <AutoPlay {...props} />}

				<ToggleControl
					label={__("Navigation", "gutenberghub-slider-ext")}
					checked={attributes?.gutenberghubNavigationOn}
					onChange={() =>
						setAttributes({
							gutenberghubNavigationOn: !attributes?.gutenberghubNavigationOn,
						})
					}
				/>
				{attributes?.gutenberghubNavigationOn && (
					<NavigationControls {...props} />
				)}

				<ToggleControl
					label={__("Indicator", "gutenberghub-slider-ext")}
					checked={attributes?.gutenberghubIndicatorOn}
					onChange={() =>
						setAttributes({
							gutenberghubIndicatorOn: !attributes?.gutenberghubIndicatorOn,
						})
					}
				/>
				{attributes.gutenberghubIndicatorOn && <IndicatorsControl {...props} />}
			</PanelBody>
		</InspectorControls>
	);
}

export default CustomInspectorControls;
