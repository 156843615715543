import { SVG, Path } from "@wordpress/primitives";

const icons = {
	chevron: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="1em"
			height="1em"
			viewBox="0 0 32 32"
		>
			<path
				fill="currentColor"
				d="M22 16L12 26l-1.4-1.4l8.6-8.6l-8.6-8.6L12 6z"
			/>
		</svg>
	),
	"chevron-compact": (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="1em"
			height="1em"
			viewBox="0 0 16 16"
		>
			<path
				fill="currentColor"
				fill-rule="evenodd"
				d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8L6.553 2.224a.5.5 0 0 1 .223-.671z"
			/>
		</svg>
	),
	"square-chevron": (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="1em"
			height="1em"
			viewBox="0 0 24 24"
		>
			<path
				fill="currentColor"
				d="M19 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.11 0 2-.89 2-2V5a2 2 0 0 0-2-2M9.71 18l-1.42-1.41L12.88 12L8.29 7.41L9.71 6l6 6l-6 6Z"
			/>
		</svg>
	),
	"caret-fill": (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="1em"
			height="1em"
			viewBox="0 0 16 16"
		>
			<path
				fill="currentColor"
				d="m12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"
			/>
		</svg>
	),
	"round-arrow": (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="1em"
			height="1em"
			viewBox="0 0 256 256"
		>
			<path
				fill="currentColor"
				d="M128 24a104 104 0 1 0 104 104A104.2 104.2 0 0 0 128 24Zm47.4 107.1a8.7 8.7 0 0 1-1.8 2.6l-33.9 33.9a7.6 7.6 0 0 1-5.6 2.3a7.8 7.8 0 0 1-5.7-2.3a8 8 0 0 1 0-11.3l20.3-20.3H88a8 8 0 0 1 0-16h60.7l-20.3-20.3a8 8 0 0 1 11.3-11.3l33.9 33.9a8.7 8.7 0 0 1 1.8 2.6a8.3 8.3 0 0 1 0 6.2Z"
			/>
		</svg>
	),
	arrow: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="1em"
			height="1em"
			viewBox="0 0 24 24"
		>
			<path
				fill="currentColor"
				d="m12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8l-8-8z"
			/>
		</svg>
	),
	caret: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="1em"
			height="1em"
			viewBox="0 0 16 16"
		>
			<path
				fill="currentColor"
				d="M6 12.796V3.204L11.481 8L6 12.796zm.659.753l5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z"
			/>
		</svg>
	),
	"round-indicators": (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="1em"
			height="1em"
			viewBox="0 0 24 24"
		>
			<path
				fill="currentColor"
				d="M16 12a2 2 0 0 1 2-2a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2m-6 0a2 2 0 0 1 2-2a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2m-6 0a2 2 0 0 1 2-2a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2Z"
			/>
		</svg>
	),
	"square-indicators": (
		<svg
			width="12"
			height="2"
			viewBox="0 0 12 2"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="2" height="2" fill="currentColor" />
			<rect x="5" width="2" height="2" fill="currentColor" />
			<rect x="10" width="2" height="2" fill="currentColor" />
		</svg>
	),
	"dash-indicators": (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="1em"
			height="1em"
			viewBox="0 0 24 24"
		>
			<path
				fill="currentColor"
				d="M4 11h4v2H4v-2Zm6 0h4v2h-4v-2Zm10 0h-4v2h4v-2Z"
			/>
		</svg>
	),
	"vertical-top": (
		<SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<Path d="M9 20h6V9H9v11zM4 4v1.5h16V4H4z" />
		</SVG>
	),

	"vertical-center": (
		<SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<Path d="M20 11h-5V4H9v7H4v1.5h5V20h6v-7.5h5z" />
		</SVG>
	),

	"vertical-bottom": (
		<SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<Path d="M15 4H9v11h6V4zM4 18.5V20h16v-1.5H4z" />
		</SVG>
	),
};
export default icons;
