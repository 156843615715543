/**
 * WordPress Dependencies
 */
import { __ } from "@wordpress/i18n";
import { useSelect } from "@wordpress/data";
import {
	Button,
	ButtonGroup,
	BaseControl,
	ColorPalette,
	__experimentalToggleGroupControl as ToggleGroupControl,
	__experimentalToggleGroupControlOptionIcon as ToggleGroupControlOptionIcon,
	__experimentalUnitControl as UnitControl,
} from "@wordpress/components";

import {
	justifyCenter,
	justifyLeft,
	justifyRight,
	justifySpaceBetween,
} from "@wordpress/icons";

import Icon from "../Icons";

function NavigationControls(props) {
	const { attributes, setAttributes } = props;

	const { defaultColors } = useSelect((select) => {
		return {
			defaultColors: select("core/block-editor")?.getSettings()
				?.__experimentalFeatures?.color?.palette?.default,
		};
	});

	const navigationStyles = [
		{
			name: __("Chevron", "gutenberghub-query-slider-ext"),
			icon: <Icon icon="chevron" />,
			id: "chevron",
		},
		{
			name: __("Chevron Compact", "gutenberghub-query-slider-ext"),
			icon: <Icon icon="chevron-compact" />,
			id: "chevronCompact",
		},
		{
			name: __("Arrow", "gutenberghub-query-slider-ext"),
			icon: <Icon icon="arrow" />,
			id: "arrow",
		},
		{
			name: __("Caret", "gutenberghub-query-slider-ext"),
			icon: <Icon icon="caret" />,
			id: "caret",
		},
		{
			icon: <Icon icon="caret-fill" />,
			name: __("Caret Filled", "gutenberghub-query-slider-ext"),
			id: "caretFill",
		},
		{
			icon: <Icon icon="round-arrow" />,
			name: __("Round with arrow", "gutenberghub-query-slider-ext"),
			id: "roundArrow",
		},
		{
			icon: <Icon icon="square-chevron" />,
			name: __("Square with chevron", "gutenberghub-query-slider-ext"),
			id: "squareChevron",
		},
	];

	const verticalAlignOpt = [
		{
			label: __("Top", "gutenberghub-query-slider-ext"),
			icon: <Icon icon="vertical-top" />,
			value: "top",
		},

		{
			label: __("Center", "gutenberghub-query-slider-ext"),
			icon: <Icon icon="vertical-center" />,
			value: "center",
		},

		{
			label: __("Bottom", "gutenberghub-query-slider-ext"),
			icon: <Icon icon="vertical-bottom" />,
			value: "bottom",
		},
	];

	const JustificationAlignOpt = [
		{
			label: __("Justify Start", "gutenberghub-query-slider-ext"),
			icon: justifyLeft,
			value: "left",
		},

		{
			label: __("Justify Center", "gutenberghub-query-slider-ext"),
			icon: justifyCenter,
			value: "center",
		},

		{
			label: __("Justify Right", "gutenberghub-query-slider-ext"),
			icon: justifyRight,
			value: "right",
		},

		{
			label: __("Justify Space Between", "gutenberghub-query-slider-ext"),
			icon: justifySpaceBetween,
			value: "spaceBetween",
		},
	];

	return (
		<>
			<BaseControl
				className="gutenberghub-slider-base-wrapper"
				label={__("Navigation Styling", "gutenberghub-slider-ext")}
			>
				<ButtonGroup className="gutenberghub-slider-gallery-button-group">
					{navigationStyles.map((navigation) => {
						return (
							<Button
								isPressed={
									attributes.gutenberghubNavigationIcon === navigation.id
								}
								icon={navigation.icon}
								label={navigation.name}
								onClick={() => {
									setAttributes({ gutenberghubNavigationIcon: navigation.id });
								}}
							/>
						);
					})}
				</ButtonGroup>
			</BaseControl>

			<ToggleGroupControl
				__nextHasNoMarginBottom
				label={__("Vertical Alignment", "gutenberghub-query-slider-ext")}
				value={attributes.gutenberghubNavigationVerticalAlign}
				onChange={(newValue) => {
					setAttributes({ gutenberghubNavigationVerticalAlign: newValue });
				}}
			>
				{verticalAlignOpt.map(({ value, icon, label }) => {
					return (
						<ToggleGroupControlOptionIcon
							key={value}
							value={value}
							icon={icon}
							label={label}
						/>
					);
				})}
			</ToggleGroupControl>

			<ToggleGroupControl
				__nextHasNoMarginBottom
				label={__("Justification", "gutenberghub-query-slider-ext")}
				value={attributes.gutenberghubNavigationJustification}
				onChange={(newValue) => {
					setAttributes({ gutenberghubNavigationJustification: newValue });
				}}
			>
				{JustificationAlignOpt.map(({ value, icon, label }) => {
					return (
						<ToggleGroupControlOptionIcon
							key={value}
							value={value}
							icon={icon}
							label={label}
						/>
					);
				})}
			</ToggleGroupControl>

			<BaseControl label={__("Size", "gutenberghub-query-slider-ext")}>
				<UnitControl
					value={attributes.gutenberghubNavigationSize}
					onChange={(newValue) =>
						setAttributes({ gutenberghubNavigationSize: newValue })
					}
				/>
			</BaseControl>

			<ColorPalette
				colors={defaultColors}
				value={attributes.navigationColor}
				onChange={(newValue) => {
					setAttributes({ navigationColor: newValue });
				}}
			/>
		</>
	);
}

export default NavigationControls;
