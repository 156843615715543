/**
 * WordPress Dependencies
 */
import { Icon as WPIcon } from "@wordpress/components";
import PropTypes from "prop-types";
import { has } from "lodash";

import icons from "./library";

function Icon(props) {
	return (
		<WPIcon icon={has(icons, props.icon) ? icons[props.icon] : props.icon} />
	);
}

Icon.propTypes = {
	icon: PropTypes.string.isRequired,
};

export default Icon;
