/**
 * WordPress Dependencies
 */
import { isEqual } from "lodash";
import { __ } from "@wordpress/i18n";
import { gallery } from "@wordpress/icons";

/** @typedef {import('@wordpress/blocks').WPBlockVariation} WPBlockVariation */

/**
 * Template option choices for predefined columns layouts.
 *
 * @type {WPBlockVariation[]}
 */

wp.blocks.registerBlockVariation("core/gallery", {
	name: "core/gallery",
	title: __("Gallery", "gutenberghub-slider-ext"),
	description: __(
		"Display multiple images in a rich gallery.",
		"gutenberghub-slider-ext"
	),
	icon: gallery,
	isDefault: true,
	category: "media",
	attributes: {
		ghubVariation: "default",
		className: "",
	},
	scope: ["inserter", "transform"],
	isActive: (blockAttributes, variationAttributes) =>
		isEqual(blockAttributes.ghubVariation, variationAttributes.ghubVariation),
});
wp.blocks.registerBlockVariation("core/gallery", {
	name: "gutenberghub-slider-gallery",
	title: __("Gallery Slider", "gutenberghub-slider-ext"),
	description: __(
		"The Gallery Slider Block enhances the default WordPress Gallery block by transforming it into a slider format, offering a more engaging way to display images in your posts or pages.",
		"gutenberghub-slider-ext"
	),
	category: "ghub-products",
	icon: (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{ fill: "none" }}
		>
			<path
				fill="currentColor"
				d="M4 19h2c0 1.103.897 2 2 2h8c1.103 0 2-.897 2-2h2c1.103 0 2-.897 2-2V7c0-1.103-.897-2-2-2h-2c0-1.103-.897-2-2-2H8c-1.103 0-2 .897-2 2H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2zM20 7v10h-2V7h2zM8 5h8l.001 14H8V5zM4 7h2v10H4V7z"
			/>
		</svg>
	),
	attributes: {
		ghubVariation: "ghub-slider-gallery",
		className: "gutenberghub-slider",
	},
	scope: ["transform", "inserter"],
	isActive: (blockAttributes, variationAttributes) =>
		isEqual(blockAttributes.ghubVariation, variationAttributes.ghubVariation),
});
