/**
 * WordPress Dependencies
 */
import { __ } from "@wordpress/i18n";
import { shuffle } from "@wordpress/icons";
import { addFilter } from "@wordpress/hooks";
import { assign, isEmpty } from "lodash";
import { createHigherOrderComponent } from "@wordpress/compose";
import { ToolbarGroup, ToolbarButton } from "@wordpress/components";
import { BlockControls } from "@wordpress/block-editor";
import { useSelect } from "@wordpress/data";
/**
 * Custom Imports
 */
import "./variations";
import "./style.scss";
import "./editor.scss";
import { shuffleInnerBlocks } from "./utils";
import CustomInspectorControls from "./inspector-controls";
/**
 * Add new attributes to the block
 */
const addAttributes = (props) => {
	const { attributes } = props;
	if (isEmpty(attributes) || props.name !== "core/gallery") {
		return props;
	}
	assign(
		props.attributes,
		{
			...attributes,
			ghubVariation: {
				type: "string",
				default: "default",
			},

			ghubSlidePerView: {
				type: "number",
				default: 3,
			},

			ghubSlideGap: {
				type: "number",
				default: 10,
			},

			ghubLoopOn: {
				type: "boolean",
				default: false,
			},

			ghubSpeed: {
				type: "number",
				default: 400,
			},

			gutenberghubHeight: {
				type: "string",
				default: "auto",
			},

			gutenberghubNavigationOn: {
				type: "boolean",
				default: true,
			},

			gutenberghubNavigationVerticalAlign: {
				type: "string",
				default: "center",
			},

			gutenberghubNavigationJustification: {
				type: "string",
				default: "spaceBetween",
			},

			gutenberghubNavigationSize: {
				type: "string",
				default: "32px",
			},

			navigationColor: {
				type: "string",
				default: "#000000",
			},

			gutenberghubAutoPlayDuration: {
				type: "number",
				default: 3000,
			},

			gutenberghubPauseOnHover: {
				type: "boolean",
				default: false,
			},

			gutenberghubAutoPlayOn: {
				type: "boolean",
				default: false,
			},

			gutenberghubNavigationIcon: {
				type: "string",
				default: "arrow",
			},

			gutenberghubIndicatorOn: {
				type: "boolean",
				default: false,
			},

			gutenberghubIndicatorStyle: {
				type: "string",
				default: "slider-indicators-round",
			},

			gutenberghubIndicatorVerticalAlign: {
				type: "string",
				default: "bottom",
			},

			gutenberghubIndicatorJustification: {
				type: "string",
				default: "center",
			},

			gutenberghubIndicatorSize: {
				type: "string",
				default: "18px",
			},

			gutenberghubIndicatorColor: {
				type: "string",
				default: "#cccccc",
			},

			gutenberghubIndicatorActiveColor: {
				type: "string",
				default: "#000000",
			},
		},
		{}
	);

	return props;
};

const withAdvanceControls = createHigherOrderComponent((BlockEdit) => {
	return (props) => {
		const { attributes, name } = props;

		if (
			name !== "core/gallery" ||
			(name === "core/gallery" &&
				attributes?.ghubVariation !== "ghub-slider-gallery")
		) {
			return <BlockEdit {...props} />;
		}

		return (
			<>
				<BlockEdit {...props} />

				<BlockControls>
					<ToolbarGroup>
						<ToolbarButton
							icon={shuffle}
							onClick={() => {
								shuffleInnerBlocks(props.clientId);
							}}
						/>
					</ToolbarGroup>
					<CustomInspectorControls {...props} />
				</BlockControls>
			</>
		);
	};
}, "withAdvanceControls");

const withBlockWrapper = createHigherOrderComponent((BlockListBlock) => {
	return (props) => {
		const { attributes, clientId } = props;
		const {
			ghubSlidePerView,
			ghubSlideGap,
			gutenberghubNavigationSize,
			gutenberghubIndicatorColor,
			gutenberghubIndicatorSize,
			gutenberghubNavigationOn,
			gutenberghubIndicatorOn,
			navigationColor,
			gutenberghubIndicatorActiveColor,
			gutenberghubNavigationIcon,
			align,
			gutenberghubNavigationVerticalAlign,
			gutenberghubNavigationJustification,
			gutenberghubIndicatorVerticalAlign,
			gutenberghubIndicatorJustification,
			gutenberghubHeight,
			gutenberghubIndicatorStyle,
		} = props.attributes;

		if (
			props.name !== "core/gallery" ||
			(props.name === "core/gallery" &&
				attributes?.ghubVariation !== "ghub-slider-gallery")
		) {
			return <BlockListBlock {...props} />;
		}

		const ghubVariables = {
			"--ghub-slider-per-view": ghubSlidePerView,
			"--ghub-slider-gap": ghubSlideGap + "px",
		};

		const navigationVariable = {
			"--ghub-slider-navigation-size": gutenberghubNavigationSize,
			"--ghub-slider-navigation-color": navigationColor,
		};

		function get_navigation_icon(icon_name) {
			const icons = {
				chevron: (
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
						<path
							fill="currentColor"
							d="M22 16L12 26l-1.4-1.4l8.6-8.6l-8.6-8.6L12 6z"
						/>
					</svg>
				),

				chevronCompact: (
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
						<path
							fill="currentColor"
							fill-rule="evenodd"
							d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8L6.553 2.224a.5.5 0 0 1 .223-.671z"
						/>
					</svg>
				),

				squareChevron: (
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
						<path
							fill="currentColor"
							d="M19 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.11 0 2-.89 2-2V5a2 2 0 0 0-2-2M9.71 18l-1.42-1.41L12.88 12L8.29 7.41L9.71 6l6 6l-6 6Z"
						/>
					</svg>
				),

				caretFill: (
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
						<path
							fill="currentColor"
							d="m12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"
						/>
					</svg>
				),

				roundArrow: (
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
						<path
							fill="currentColor"
							d="M128 24a104 104 0 1 0 104 104A104.2 104.2 0 0 0 128 24Zm47.4 107.1a8.7 8.7 0 0 1-1.8 2.6l-33.9 33.9a7.6 7.6 0 0 1-5.6 2.3a7.8 7.8 0 0 1-5.7-2.3a8 8 0 0 1 0-11.3l20.3-20.3H88a8 8 0 0 1 0-16h60.7l-20.3-20.3a8 8 0 0 1 11.3-11.3l33.9 33.9a8.7 8.7 0 0 1 1.8 2.6a8.3 8.3 0 0 1 0 6.2Z"
						/>
					</svg>
				),

				arrow: (
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
						<path
							fill="currentColor"
							d="m12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8l-8-8z"
						/>
					</svg>
				),

				caret: (
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
						<path
							fill="currentColor"
							d="M6 12.796V3.204L11.481 8L6 12.796zm.659.753l5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z"
						/>
					</svg>
				),

				roundIndicators: (
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
						<path
							fill="currentColor"
							d="M16 12a2 2 0 0 1 2-2a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2m-6 0a2 2 0 0 1 2-2a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2m-6 0a2 2 0 0 1 2-2a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2Z"
						/>
					</svg>
				),

				squareIndicators: (
					<svg
						width="12"
						height="2"
						viewBox="0 0 12 2"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<rect width="2" height="2" fill="currentColor" />
						<rect x="5" width="2" height="2" fill="currentColor" />
						<rect x="10" width="2" height="2" fill="currentColor" />
					</svg>
				),

				dashIndicators: (
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
						<path
							fill="currentColor"
							d="M4 11h4v2H4v-2Zm6 0h4v2h-4v-2Zm10 0h-4v2h4v-2Z"
						/>
					</svg>
				),
			};
			return icons[icon_name];
		}

		const innerBlockImages = useSelect(
			(select) => {
				return select("core/block-editor").getBlock(clientId).innerBlocks;
			},
			[clientId]
		);

		const ghubAlign =
			typeof align === "undefined" ? "alignnone" : `align${align}`;

		const navigationVerticalAlign =
			typeof gutenberghubNavigationVerticalAlign !== "undefined"
				? `ghub-navigation-vertical-${gutenberghubNavigationVerticalAlign}`
				: "ghub-navigation-vertical-center";

		const navigationJustification =
			typeof gutenberghubNavigationJustification !== "undefined"
				? `ghub-navigation-justify-${gutenberghubNavigationJustification}`
				: "ghub-navigation-justify-center";

		const paginationVariable = {
			"--ghub-slider-paginationSize": gutenberghubIndicatorSize,
			"--ghub-slider-pagination-inactive-color": gutenberghubIndicatorColor,
			"--ghub-slider-pagination-active-color": gutenberghubIndicatorActiveColor,
		};

		const paginationVerticalAlign =
			typeof gutenberghubIndicatorVerticalAlign !== "undefined"
				? `ghub-pagination-vertical-${gutenberghubIndicatorVerticalAlign}`
				: "ghub-pagination-vertical-bottom";

		const paginationJustification =
			typeof gutenberghubIndicatorJustification !== "undefined"
				? `ghub-pagination-justify-${gutenberghubIndicatorJustification}`
				: "ghub-pagination-justify-center";

		return (
			<div
				className={`ghub-slider-gallery-container ${ghubAlign} ghub-${gutenberghubHeight}`}
			>
				<BlockListBlock
					{...props}
					wrapperProps={{ style: ghubVariables }}
					className="ghub-gallery-slider-editor"
				/>
				{!isEmpty(innerBlockImages) && gutenberghubNavigationOn && (
					<div
						className={`ghub-slider-navigation-wrapper ${navigationVerticalAlign} ${navigationJustification}`}
						style={navigationVariable}
					>
						<div className="ghub-prev-button">
							{get_navigation_icon(gutenberghubNavigationIcon)}
						</div>
						<div className="ghub-next-button">
							{get_navigation_icon(gutenberghubNavigationIcon)}
						</div>
					</div>
				)}
				{gutenberghubIndicatorOn && (
					<div
						className={`ghub-pagination-wrapper ${paginationVerticalAlign} ${paginationJustification} ${gutenberghubIndicatorStyle}`}
						style={paginationVariable}
					>
						<span className="ghub-pagination-bullet ghub-active"></span>
						<span className="ghub-pagination-bullet"></span>
						<span className="ghub-pagination-bullet"></span>
					</div>
				)}
			</div>
		);
	};
}, "withBlockWrapper");

addFilter(
	"editor.BlockEdit",
	"gutenberghub-slider-ext/with-advance-controls",
	withAdvanceControls
);
addFilter(
	"blocks.registerBlockType",
	"gutenberghub-slider-ext/add-attributes",
	addAttributes
);
addFilter(
	"editor.BlockListBlock",
	"gutenberghub-slider-ext/with-block-wrapper",
	withBlockWrapper
);
