/**
 * WordPress Dependencies
 */
import { __ } from "@wordpress/i18n";
import {
	Button,
	ButtonGroup,
	BaseControl,
	ColorPalette,
	__experimentalToggleGroupControl as ToggleGroupControl,
	__experimentalToggleGroupControlOptionIcon as ToggleGroupControlOptionIcon,
	__experimentalUnitControl as UnitControl,
} from "@wordpress/components";
import { useSelect } from "@wordpress/data";
import { justifyCenter, justifyLeft, justifyRight } from "@wordpress/icons";

import Icon from "../Icons";

function IndicatorsControl(props) {
	const { attributes, setAttributes } = props;

	const indicatorStyles = [
		{
			name: "Round indicators",
			icon: <Icon icon="round-indicators" />,
			id: "slider-indicators-round",
		},
		{
			name: "Square indicators",
			icon: <Icon icon="square-indicators" />,
			id: "slider-indicators-square",
		},
	];

	const verticalAlignOpt = [
		{
			label: __("Top", "gutenberghub-query-slider-ext"),
			icon: <Icon icon="vertical-top" />,
			value: "top",
		},

		{
			label: __("Center", "gutenberghub-query-slider-ext"),
			icon: <Icon icon="vertical-center" />,
			value: "center",
		},

		{
			label: __("Bottom", "gutenberghub-query-slider-ext"),
			icon: <Icon icon="vertical-bottom" />,
			value: "bottom",
		},
	];

	const JustificationAlignOpt = [
		{
			label: __("Justify Start", "gutenberghub-query-slider-ext"),
			icon: justifyLeft,
			value: "left",
		},

		{
			label: __("Justify Center", "gutenberghub-query-slider-ext"),
			icon: justifyCenter,
			value: "center",
		},

		{
			label: __("Justify Right", "gutenberghub-query-slider-ext"),
			icon: justifyRight,
			value: "right",
		},
	];

	const { defaultColors } = useSelect((select) => {
		return {
			defaultColors: select("core/block-editor")?.getSettings()
				?.__experimentalFeatures?.color?.palette?.default,
		};
	});

	return (
		<BaseControl
			className="gutenberghub-slider-base-wrapper"
			label={__("Indicators Styling", "gutenberghub-query-slider-ext")}
		>
			<ButtonGroup className="gutenberghub-slider-gallery-button-group">
				{indicatorStyles.map((indicator) => {
					return (
						<Button
							isPressed={attributes.gutenberghubIndicatorStyle === indicator.id}
							icon={indicator.icon}
							label={indicator.name}
							onClick={() => {
								setAttributes({ gutenberghubIndicatorStyle: indicator.id });
							}}
						/>
					);
				})}
			</ButtonGroup>

			<ToggleGroupControl
				__nextHasNoMarginBottom
				label={__("Vertical Alignment", "gutenberghub-query-slider-ext")}
				value={attributes.gutenberghubIndicatorVerticalAlign}
				onChange={(newValue) => {
					setAttributes({ gutenberghubIndicatorVerticalAlign: newValue });
				}}
			>
				{verticalAlignOpt.map(({ value, icon, label }) => {
					return (
						<ToggleGroupControlOptionIcon
							key={value}
							value={value}
							icon={icon}
							label={label}
						/>
					);
				})}
			</ToggleGroupControl>

			<ToggleGroupControl
				__nextHasNoMarginBottom
				label={__("Justification", "gutenberghub-query-slider-ext")}
				value={attributes.gutenberghubIndicatorJustification}
				onChange={(newValue) => {
					setAttributes({ gutenberghubIndicatorJustification: newValue });
				}}
			>
				{JustificationAlignOpt.map(({ value, icon, label }) => {
					return (
						<ToggleGroupControlOptionIcon
							key={value}
							value={value}
							icon={icon}
							label={label}
						/>
					);
				})}
			</ToggleGroupControl>

			<BaseControl label={__("Size", "gutenberghub-query-slider-ext")}>
				<UnitControl
					value={attributes.gutenberghubIndicatorSize}
					onChange={(newValue) =>
						setAttributes({ gutenberghubIndicatorSize: newValue })
					}
				/>
			</BaseControl>

			<BaseControl
				label={__("Active Indicators Color", "gutenberghub-query-slider-ext")}
			>
				<ColorPalette
					colors={defaultColors}
					value={attributes.gutenberghubIndicatorActiveColor}
					onChange={(newValue) => {
						setAttributes({ gutenberghubIndicatorActiveColor: newValue });
					}}
				/>
			</BaseControl>
			<BaseControl
				label={__("Inactive Indicators Color", "gutenberghub-query-slider-ext")}
			>
				<ColorPalette
					colors={defaultColors}
					value={attributes.gutenberghubIndicatorColor}
					onChange={(newValue) => {
						setAttributes({ gutenberghubIndicatorColor: newValue });
					}}
				/>
			</BaseControl>
		</BaseControl>
	);
}

export default IndicatorsControl;
