import { select, dispatch } from "@wordpress/data";

export const shuffleInnerBlocks = (clientId) => {
	const { replaceInnerBlocks } = dispatch("core/block-editor");
	const block = select("core/block-editor").getBlock(clientId);
	const innerBlocks = block.innerBlocks;
	innerBlocks.sort(() => Math.random() - 0.5);

	replaceInnerBlocks(clientId, innerBlocks, false);
};
