/**
 * WordPress Dependencies
 */
import { __ } from "@wordpress/i18n";
import {
	ToggleControl,
	RangeControl,
	BaseControl,
} from "@wordpress/components";

function AutoPlay(props) {
	const { attributes, setAttributes } = props;

	return (
		<BaseControl className="gutenberghub-slider-base-wrapper">
			<RangeControl
				min={0}
				max={10000}
				allowReset
				initialPosition={3000}
				value={attributes.gutenberghubAutoPlayDuration}
				label={__("Autoplay Timeout", "gutenberghub-slider-ext")}
				onChange={(newValue) => {
					setAttributes({ gutenberghubAutoPlayDuration: newValue });
				}}
			/>
			<ToggleControl
				label={__("Pause on hover", "gutenberghub-slider-ext")}
				checked={attributes?.gutenberghubPauseOnHover}
				onChange={() =>
					setAttributes({
						gutenberghubPauseOnHover: !attributes?.gutenberghubPauseOnHover,
					})
				}
			/>
		</BaseControl>
	);
}

export default AutoPlay;
